<template>
  <div class="page-table musteriTanim  mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">
      Resmi Tatil Tanımla
    </div>

    <el-row :gutter="10" v-loading="loading"
            :element-loading-text='$t("src.views.apps.musteri.tanimlama.addLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="tatilForm" :rules="rulesMusteri" ref="tatilForm" class="demo-ruleForm">
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10">
            <el-row>
              <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Özel Gün Adı" prop="sebep">:
                  <el-input size="small"
                            v-model="tatilForm.sebep"
                            :placeholder="'Özel Gün Adı Giriniz'">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Tatil Türü" prop="tamGun">:
                  <el-select
                      size="small"
                      v-model="tatilForm.tamGun"
                      style="width: 100%">
                    <el-option value="1" label="Tam Gün"></el-option>
                    <el-option value="2" label="Yarım Gün"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Tatil Tarihleri" prop="tatilTarihleri">:
                  <el-date-picker
                      v-model="tatilForm.tatilTarihleri"
                      size="small"
                      type="daterange"
                      range-separator=""
                      start-placeholder="Başlangıç Tarihi"
                      end-placeholder="Bitiş Tarihi"
                      format="dd.MM.yyyy"
                      value-format="yyyy-MM-dd"
                      :picker-options="{ firstDayOfWeek: 1 }"
                      style="width: 100%">
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Toplam Gün" prop="gunMiktari">:
                  <el-input :input="gunMiktari(tatilForm.tatilTarihleri)"
                            size="small"
                            v-model="tatilForm.gunMiktari"
                            :placeholder="'Toplam Tatil Günü Giriniz'"
                            v-mask="'#####'">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>


          </div>

        </el-col>

        <el-col :lg="6" :md="6" :sm="24" :xs="24">
          <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
            <el-collapse-item class="card-shadow--medium" name="8" :title='$t("src.views.apps.genel.durum")'>
              <el-row>

                <el-button size="mini" v-on:click="tatilEkle('tatilForm',tatilForm.durum)" class="onayBtn"
                           type="primary">
                  <label>Kaydet</label>
                </el-button>
                &nbsp;
                <el-popover placement="bottom" width="310" v-model="visibleSil">
                  <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="primary" @click="resetForm('tatilForm')">
                      {{ $t('src.views.apps.genel.yes') }}
                    </el-button>
                    <el-button size="mini" @click="visibleSil = false;">
                      {{ $t('src.views.apps.genel.no') }}
                    </el-button>
                  </div>
                  <el-button size="mini" slot="reference">
                    {{ $t('src.views.apps.genel.temizle') }}
                  </el-button>
                </el-popover>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
import draggable from "vuedraggable";

import tatilService from '../../../WSProvider/TatilService'

import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'
import Resim from '@/components/Resim.vue'
import Seo from '@/components/Seo.vue'
import Konumlar from '@/components/Konumlar.vue'
import JQuery from 'jquery';

let $ = JQuery;

var moment = require('moment');
let time = moment().format('YYYY-MM-DD HH:mm:ss');

export default {
  name: "TatilTanimla",
  components: {
    draggable,
    Resim,
    Seo,
    Konumlar
  },

  mounted() {
    const self = this;
  },

  data() {
    return {
      totalKategori: 0, // kategorilerin sayısını ilk önce cekerek kategori listesini komple cekmek için
      catLoading: false,
      kategoriListe: [],

      loading: false,
      selectedImagesFromServer: [],
      path: tatilService.path,
      imagePath: tatilService.imagePath,
      belgePath: tatilService.belgePath,

      videoVis: false,
      editor: ClassicEditor,
      editorConfig: {
        language: 'tr',
      },
      activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11"],
      visibleSil: false,
      disabled: false,
      tatilForm: {
        sebep: '',
        tatilBaslangic: '',
        tatilBitis: '',
        tatilTarihleri: '',
        tamGun: '',
        gunMiktari: '',
        durum: '1',
      },
      rulesMusteri: {
        sebep: [{
          required: true,
          message: "Lütfen Özel Gün Giriniz",
          trigger: 'blur'
        }],
        tamGun: [{
          required: true,
          message: "Lütfen Tatil Türü Seçiniz",
          trigger: 'blur'
        }],
        gunMiktari: [{
          required: true,
          message: "Lütfen Tatil Günü Giriniz",
          trigger: 'blur'
        }],
      },
    }
  },
  methods: {
    gunMiktari(item) {
      var baslangicTarihi = item[0];
      var bitisTarihi = item[1];

      var baslangic = new Date(baslangicTarihi);
      var bitis = new Date(bitisTarihi);

      // console.log(baslangic)
      // console.log(bitis)

      var birGun = 24 * 60 * 60 * 1000;

      var gunFarki = Math.round(Math.abs((bitis - baslangic) / birGun)) + 1;

      //  console.log(gunFarki);
      this.tatilForm.gunMiktari = gunFarki ? gunFarki : ""
    },
    tatilEkle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Resmi tatili tanımlamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            this.loading = true;
            var baslamaTarihi;
            var bitisTarihi;
            this.tatilForm.tatilTarihleri.forEach((tarih, index) => {
              if (index === 0)
                baslamaTarihi = tarih;
              else
                bitisTarihi = tarih;

            })
            tatilService.resmiTatilEkle(baslamaTarihi, bitisTarihi, this.tatilForm.sebep, this.tatilForm.tamGun, this.tatilForm.gunMiktari).then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token)
                this.resetForm(formName)
                functions.sayfaKapat("tatiltanimla", this);
                EventBus.$emit("tatilList", true)
                functions.routeSayfa("Tatil Listesi", this)
              }
              this.loading = false;
              notification.Status("success", this, response.msg)
            }).catch(err => {
              if (err.responseJSON) {
                let error = err.responseJSON
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg)
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            })
          }).catch((e) => {
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
    },
  }
}

</script>
